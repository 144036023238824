<template>
  <g>
    <svg:style>
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke:#aeaeae;
        stroke-width:0.25px;
      }
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke-linecap:round;
        stroke-linejoin:round;
      }
      .cls-4{fill:url(#inox-gradient);}
      .cls-5{fill:url(#inox-gradient-2);}
      .cls-6{fill:url(#inox-gradient-3);}
      .cls-7{fill:url(#inox-gradient-4);}
      .cls-8{fill:url(#inox-gradient-5);}
      .cls-9{fill:url(#glass-pattern);}
      .cls-10{fill:url(#inox-gradient-6);}
      .cls-11{fill:url(#glass-pattern);}
      .cls-12{fill:url(#inox-gradient-7);}
      .cls-13{fill:url(#glass-pattern);}
    </svg:style>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.33"
                    :y1="doorTopHeight1 + 150.96"
                    :x2="doorLeftWidth1 + 17.01"
                    :y2="doorTopHeight1 + 150.96"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 85.54"
                    :y1="doorTopHeight1 + 182.96"
                    :x2="doorLeftWidth1 + 119.55"
                    :y2="doorTopHeight1 + 182.96"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-2"
                    :x1="doorLeftWidth1 + 84.59"
                    :y1="doorTopHeight1 + 190.46"
                    :x2="doorLeftWidth1 + 119.55"
                    :y2="doorTopHeight1 + 190.46"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-3"
                    :x1="doorLeftWidth1 + 22.2"
                    :y1="doorTopHeight1 + 118.32"
                    :x2="doorLeftWidth1 + 56.19"
                    :y2="doorTopHeight1 + 118.32"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-4"
                    :x1="doorLeftWidth1 + 22.2"
                    :y1="doorTopHeight1 + 110.82"
                    :x2="doorLeftWidth1 + 57.25"
                    :y2="doorTopHeight1 + 110.82"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-5"
                    :x1="doorLeftWidth1 + 61.05"
                    :y1="doorTopHeight1 + 78.5"
                    :x2="doorLeftWidth1 + 98.58"
                    :y2="doorTopHeight1 + 78.5"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-6"
                    :x1="doorLeftWidth1 + 51.35"
                    :y1="doorTopHeight1 + 150.54"
                    :x2="doorLeftWidth1 + 88.88"
                    :y2="doorTopHeight1 + 150.54"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-7"
                    :x1="doorLeftWidth1 + 41.42"
                    :y1="doorTopHeight1 + 222.78"
                    :x2="doorLeftWidth1 + 78.95"
                    :y2="doorTopHeight1 + 222.78"
                    xlink:href="#handle-gradient"/>

    <g id="V29">
      <polygon id="inox"
               v-if="showInox"
               class="cls-4"
               :points="`
                ${doorLeftWidth + doorWidth - inoxOffset} ${doorTopHeight1 + 182.06}
                ${doorLeftWidth1 + 85.87} ${doorTopHeight1 + 182.06}
                ${doorLeftWidth1 + 85.54} ${doorTopHeight1 + 183.87}
                ${inox1} ${doorTopHeight1 + 183.87}
                ${inox1} ${doorTopHeight1 + 182.06}
               `"/>
      <polygon id="inox-2"
               v-if="showInox"
               data-name="inox"
               class="cls-5"
               :points="`
                ${doorLeftWidth1 + 84.82} ${doorTopHeight1 + 189.56}
                ${doorLeftWidth1 + 84.59} ${doorTopHeight1 + 191.36}
                ${doorLeftWidth1 + 84.6} ${doorTopHeight1 + 191.36}
                ${inox1} ${doorTopHeight1 + 191.36}
                ${inox1} ${doorTopHeight1 + 189.56} ${doorLeftWidth1 + 84.82} ${doorTopHeight1 + 189.56}
               `"/>
      <polygon id="inox-3"
               v-if="showInox"
               data-name="inox"
               class="cls-6"
               :points="`
                ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 119.22}
                ${doorLeftWidth1 + 56.19} ${doorTopHeight1 + 117.42}
                ${inox2} ${doorTopHeight1 + 117.42}
                ${inox2} ${doorTopHeight1 + 119.22}
                ${doorLeftWidth1 + 55.95} ${doorTopHeight1 + 119.22}
               `"/>
      <polygon id="inox-4"
               v-if="showInox"
               data-name="inox"
               class="cls-7"
               :points="`
                ${doorLeftWidth + inoxOffset} ${doorTopHeight1 + 111.73}
                ${doorLeftWidth1 + 56.95} ${doorTopHeight1 + 111.73}
                ${doorLeftWidth1 + 57.26} ${doorTopHeight1 + 109.92}
                ${inox2} ${doorTopHeight1 + 109.92}
                ${inox2} ${doorTopHeight1 + 111.73}
               `"/>
      <polygon id="inox-5"
               v-if="showInox"
               data-name="inox"
               class="cls-8"
               :points="`
                ${doorLeftWidth1 + 69.96} ${doorTopHeight1 + 45.28}
                ${doorLeftWidth1 + 98.58} ${doorTopHeight1 + 45.28}
                ${doorLeftWidth1 + 89.59} ${doorTopHeight1 + 111.73}
                ${doorLeftWidth1 + 61.05} ${doorTopHeight1 + 111.73}
                ${doorLeftWidth1 + 69.96} ${doorTopHeight1 + 45.28}
               `"/>
      <polygon id="glass"
               class="cls-9"
               :points="`${doorLeftWidth1 + 74.33} ${doorTopHeight1 + 50.21} ${doorLeftWidth1 + 66.73} ${doorTopHeight1 + 106.8} ${doorLeftWidth1 + 85.27} ${doorTopHeight1 + 106.8} ${doorLeftWidth1 + 92.86} ${doorTopHeight1 + 50.21} ${doorLeftWidth1 + 74.33} ${doorTopHeight1 + 50.21}`"/>
      <polygon id="inox-6"
               v-if="showInox"
               data-name="inox"
               class="cls-10"
               :points="`${doorLeftWidth1 + 60.26} ${doorTopHeight1 + 117.32} ${doorLeftWidth1 + 88.88} ${doorTopHeight1 + 117.32} ${doorLeftWidth1 + 79.89} ${doorTopHeight1 + 183.76} ${doorLeftWidth1 + 51.35} ${doorTopHeight1 + 183.76} ${doorLeftWidth1 + 60.26} ${doorTopHeight1 + 117.32}`"/>
      <polygon id="glass-2"
               data-name="glass"
               class="cls-11"
               :points="`${doorLeftWidth1 + 64.62} ${doorTopHeight1 + 122.25} ${doorLeftWidth1 + 57.03} ${doorTopHeight1 + 178.83} ${doorLeftWidth1 + 75.56} ${doorTopHeight1 + 178.83} ${doorLeftWidth1 + 83.16} ${doorTopHeight1 + 122.25} ${doorLeftWidth1 + 64.62} ${doorTopHeight1 + 122.25}`"/>
      <polygon id="inox-7"
               v-if="showInox"
               data-name="inox"
               class="cls-12"
               :points="`${doorLeftWidth1 + 50.34} ${doorTopHeight1 + 189.56} ${doorLeftWidth1 + 78.95} ${doorTopHeight1 + 189.56} ${doorLeftWidth1 + 69.97} ${doorTopHeight1 + 256} ${doorLeftWidth1 + 41.42} ${doorTopHeight1 + 256} ${doorLeftWidth1 + 50.34} ${doorTopHeight1 + 189.56}`"/>
      <polygon id="glass-3"
               data-name="glass"
               class="cls-13"
               :points="`${doorLeftWidth1 + 54.7} ${doorTopHeight1 + 194.49} ${doorLeftWidth1 + 47.1} ${doorTopHeight1 + 251.07} ${doorLeftWidth1 + 65.64} ${doorTopHeight1 + 251.07} ${doorLeftWidth1 + 73.23} ${doorTopHeight1 + 194.49} ${doorLeftWidth1 + 54.7} ${doorTopHeight1 + 194.49}`"/>
    </g>
  </g>
</template>


<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22,
      leafOffset: 6.4,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    inox1() {
      return this.showBg ?
      this.doorLeftWidth + this.doorWidth - this.inoxOffset:
      this.doorLeftWidth + this.doorWidth - this.leafOffset
    },
    inox2() {
      return this.showBg ?
      this.doorLeftWidth + this.inoxOffset:
      this.doorLeftWidth + this.leafOffset
    },
  },
}
</script>
